<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <!-- Table Container Card -->

        <b-card no-body>
            <b-card-body style="padding-top: 20px !important;">

                    <!-- Table Top -->
                    <b-row>
                        <!-- Per Page -->
                        <b-col cols="12" lg="5" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem">
                            <label>Show</label>
                            <v-select
                                v-model="perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block mx-50"
                                @input="updatePagination"
                                style="width: 86px;"
                            />
                            <label>entries</label>
                            <label class="label-space" style="display: block" v-if="total_selected > 0"> Total Selected Category - {{ total_selected }} </label>
                        </b-col>

                        <!-- <b-col cols="12" md="8" class="mobile_tab_max_width_flex" v-if="total_selected > 0">
                            <label style="padding-top: 8px !important;" class="" v-if="total_selected > 0"> Total Selected Category - {{ total_selected }} </label>
                        </b-col> -->
                        
                        <b-col cols="12" lg="7" class="mobile_tab_max_width_flex">
                            <div class="d-flex align-items-center justify-content-end mobile-view mr_1_last_child mobile_tab_display_block">
                                
                                <!-- <label style="padding-top: 5px !important;" class="mr-1" v-if="total_selected > 0">  Total Selected Category - {{ total_selected }} </label> -->

                                <b-button class="mr-1 mobile-margin-bottom" variant="warning" :to="{name:'location-qr-category-add'}" v-if="checkPermission($route.name, 'Add')">
                                    <span class="text-nowrap">Add</span>
                                </b-button>

                                <b-button
                                    v-if="checkPermission($route.name, 'Assign to Project Location')"
                                    variant="success"
                                    class="mr-1 mobile-margin-bottom"
                                    @click="openLocationModel"
                                    :disabled="total_selected == 0 ? true : false"
                                >
                                    <span class="text-nowrap">Assign to Project Locations</span>
                                </b-button>

                                <b-button
                                    v-if="checkPermission($route.name, 'Delete')"
                                    variant="danger" 
                                    class="mr-1 mobile-margin-bottom" 
                                    @click="changeMultipleStatus('archive')"
                                    :disabled="total_selected == 0 ? true : false"
                                >
                                    <span class="text-nowrap">Delete</span>
                                </b-button>

                                <!-- <b-button variant="info" class="mr-1 mobile-margin-bottom" :to="{ name: 'location-active-qr'}">
                                    <span class="text-nowrap">Active QR</span>
                                </b-button>
                                
                                <b-button                                
                                    variant="primary"
                                    class="mobile-margin-bottom"
                                    @click="openAssignSiteModel"
                                    :disabled="total_selected == 0 ? true : false"
                                >
                                    <span class="text-nowrap">Generate QR</span>
                                </b-button> -->
                                
                            </div>
                        </b-col>

                    </b-row>
                
            </b-card-body>
        </b-card>
        
        <b-card no-body class="mb-0 pt-1">

            <!-- <div class="px-4"> -->
                <!-- <span v-for="(item, ind) in items" :key="ind" v-if="item.selected" class="badge badge-pill badge-warning mr-1 mb-1"> {{ item.name }} </span> -->
                <!-- <h3 class="text-white" style="margin-top: 8px;" v-if="total_selected > 0">  Total Categories Selected - {{ total_selected }} </h3> -->
            <!-- </div> -->

            <div class="p-1 px-2 pt-1">
                <b-row v-if="items_paged.length > 0">

                    <div class="text-center">
                        <div class="card-deck text-center custom-card qrCard" v-for="(item,index) in items_paged" :key="index">

                            <div class="card box-shadow border" :class="item.selected == false ? 'border-secondary' : 'border-danger'" @click="updateSelect(item._id)">
                                
                                <div :class="item.status == 'active' ? 'bg-success' : 'bg-danger'" style="padding: 5px; width: 5px; border-radius: 25px; margin-left: auto; margin-right: 2px;margin-top:2px"/>
                                <div class="w-100 text-center">
                                    <img :src="item.image" variant="light-primary" class="rounded text-center" style="width:70%; padding-top: 0px"/>
                                </div>
                                <p class="text-center mt-1 categoryH5 pb-0 mb-0 text-white" style="font-size: 11px;" :title="item.name.length > 20 ? item.name : ''"> {{item.name | truncate20 | capitalize}} </p> 
                                <p class="text-center categoryH5 pb-0 mb-0 text-white" style="font-size: 11px;">( {{item.category_type | capitalize}} )</p>
                                
                                <div class="business-items qrLocFooter" bis_skin_checked="1" v-if="checkPermission($route.name, 'Edit') || checkPermission($route.name, 'Change Status')">
                                    <div class="business-item" bis_skin_checked="1">

                                        <div class="d-flex align-items-center justify-content-between" bis_skin_checked="1">

                                            <b-link v-b-tooltip.hover.v-warning title="Edit" variant="outline-warning" v-if="checkPermission($route.name, 'Edit')" :to="{ name: 'location-qr-category-edit', params: { id: item._id } }">
                                                <feather-icon icon="EditIcon" class="mediumSize ml-1" />    
                                            </b-link>

                                            <!-- <b-link v-b-tooltip.hover.v-warning title="Delete" variant="outline-warning" @click="deleteItem(item._id)">
                                                <feather-icon icon="Trash2Icon" class="mediumSize ml-" />    
                                            </b-link> -->

                                            <b-form-checkbox
                                                value="active"
                                                unchecked-value="inactive"
                                                class="custom-control-primary ml-1"
                                                switch
                                                v-model="item.status"
                                                :key="item._id"
                                                @change="updateStatus(item)"
                                                v-if="checkPermission($route.name, 'Change Status')"
                                            />

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    
                </b-row>
                <div v-else>
                    <p class="text-center h5"> No Location Found. </p>
                </div>
            </div>

            <div class="mx-2 mb-2">
                <b-row>

                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                    </b-col>

                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecords"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @input="updatePagination"
                        >
                        <!-- @input="filterTable" -->
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>

                </b-row>
            </div>

        </b-card>

        <b-modal 
            id="pass"
            ref="pass"
            cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            centered
            title="Verify Password"
            @ok="verifyPassword"
            no-close-on-backdrop
        >
            <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body"> {{error_message}} </div>
            </b-alert>

            <b-form>
                <b-row>
                    <b-col md="12">
                        <b-form-group label="Password" class="required">
                            <b-form-input placeholder="" v-model="password"/>
                        </b-form-group>
                    </b-col>
                </b-row>      
            </b-form>

        </b-modal>

        <!-- Assign To Project Site Model -->
        <b-modal
            id="assign_site"
            ref="assign_site"
            cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            centered
            title="Generate QR Code"
            @ok="generateQR"
            no-close-on-backdrop
            size="lg"
        >

            <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body">
                    {{error_message}}
                </div>
            </b-alert>

            <b-form>
                <b-row>
                    <b-col cols="12" md="12" class="mb-md-0 mb-2">
                    <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="all_sites"
                        label="site_name"
                        class="w-100"
                        placeholder="Select Project Site"
                        v-model="site_id"
                        @input="getLocations()"
                        :clearable="false"
                    />
                    </b-col>
                </b-row>

                <b-row class="mt-1">
                    
                  <b-col md="12">
                    <b-form-group
                      label=""
                      class="mb-0"
                    >
                      <div class="demo-inline-spacing">
                        <b-form-radio
                          class="mb-0 mt-1"
                          
                          name="some-radios1"
                          value="male"
                          v-model="toilet_type"
                          @change="locations = []"
                        >
                          Male Toilet
                        </b-form-radio>

                        <b-form-radio
                          class="mb-0 mt-1"
                          
                          name="some-radios1"
                          value="female"
                          v-model="toilet_type"
                          @change="locations = []"
                        >
                          Female Toilet
                        </b-form-radio>

                        <b-form-radio
                          class="mb-0 mt-1"
                          
                          name="some-radios1"
                          value="handicapped"
                          v-model="toilet_type"
                          @change="locations = []"
                        >
                          Handicap Toilet
                        </b-form-radio>

                        <b-form-radio
                          class="mb-0 mt-1"
                          
                          name="some-radios1"
                          value="other"
                          v-model="toilet_type"
                          @change="locations = []"
                        >
                          Other
                        </b-form-radio>

                        <b-form-radio
                          class="mb-0 mt-1"
                          
                          name="some-radios1"
                          value="custom"
                          v-model="toilet_type"
                          @change="locations = []"
                        >
                          Custom
                        </b-form-radio>

                      </div>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row class="mt-1" v-if="toilet_type == 'custom'">
                    <b-col cols="12" md="12" class="mb-md-0 mb-2">
                    <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="all_locations"
                        label="name"
                        multiple
                        class="w-100"
                        placeholder="Select Locations"
                        v-model="locations"
                        :close-on-select=false
                        :clear-on-select=false
                    />
                    </b-col>
                </b-row>



                <b-row class="mt-1">
                    <b-col md="12">
                        <!-- <b-form-group
                          label="Gender"
                          class="required mb-0"
                        > -->
                          <div class="demo-inline-spacing">
                            <b-form-radio
                              class="mb-0 mt-1"
                              
                              name="some-radios"
                              value="yellow"
                              v-model="theme"
                              @change="resetFile()"
                            >
                              Yellow Theme
                            </b-form-radio>

                            <b-form-radio
                              class="mb-0 mt-1"
                              
                              name="some-radios"
                              value="blue"
                              v-model="theme"
                              @change="resetFile()"
                            >
                              Blue Theme
                            </b-form-radio>

                            <b-form-radio
                              class="mb-0 mt-1"
                              
                              name="some-radios"
                              value="custom"
                              v-model="theme"
                              @change="resetFile()"
                            >
                              Custom Theme
                            </b-form-radio>
                          </div>
                        <!-- </b-form-group> -->
                    </b-col>
                </b-row>
                <b-row class="mt-2" v-if="theme == 'custom'">
                    <b-col md="12">
                        <b-form-group
                          label="Upload Template"  
                          class="required mb-1"
                        >
                          <b-form-file
                            id="upload_file"
                            ref="uploadFile"
                            accept=".png"
                            @change="onFileChange"
                            
                          />
                          <small class="text-danger">supported format: .png</small>
                        </b-form-group>
                  </b-col>
                </b-row>


                
            </b-form>
        </b-modal>

        <!-- Assign To Project Location Model -->
        <b-modal
            id="assign_location"
            ref="assign_location"
            cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            centered
            title="Project Site Locations"
            @ok="updateLocations"
            no-close-on-backdrop
            size="lg"
        >

            <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body">
                    {{error_message}}
                </div>
            </b-alert>

            <b-form>
                <b-row>
                    <b-col cols="12" md="12" class="mb-md-0 mb-2">
                    <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="all_sites"
                        label="site_name"
                        class="w-100"
                        placeholder="Select Project Site"
                        v-model="site_id"
                        @input="getLocations()"
                        :clearable="false"
                    />
                    </b-col>
                </b-row>

                <b-row class="mt-1">
              
                  <b-col md="12">
                    <b-form-group
                      label=""
                      class="mb-0"
                    >
                      <div class="demo-inline-spacing">
                        <b-form-radio
                          class="mb-0 mt-1"
                          
                          name="some-radios"
                          value="male"
                          v-model="toilet_type"
                          @change="locations = []"
                        >
                          Male Toilet
                        </b-form-radio>

                        <b-form-radio
                          class="mb-0 mt-1"
                          
                          name="some-radios"
                          value="female"
                          v-model="toilet_type"
                          @change="locations = []"
                        >
                          Female Toilet
                        </b-form-radio>

                        <b-form-radio
                          class="mb-0 mt-1"
                          
                          name="some-radios"
                          value="handicapped"
                          v-model="toilet_type"
                          @change="locations = []"
                        >
                          Handicap Toilet
                        </b-form-radio>

                        <b-form-radio
                          class="mb-0 mt-1"
                          
                          name="some-radios"
                          value="custom"
                          v-model="toilet_type"
                          @change="locations = []"
                        >
                          Custom
                        </b-form-radio>

                      </div>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row class="mt-2" v-if="toilet_type == 'custom'">
                    <b-col cols="12" md="12" class="mb-md-0 mb-2">
                    <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="all_locations"
                        label="name"
                        multiple
                        class="w-100"
                        placeholder="Select Locations"
                        v-model="locations"
                        :close-on-select=false
                        :clear-on-select=false
                    />
                    </b-col>
                </b-row>
                
            </b-form>
        </b-modal>
    
    </div>
</template>

<script>
    import {
        BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm,BFormRadio, BBreadcrumb, BFormFile, VBTooltip
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import { ref, onUnmounted } from '@vue/composition-api'
    import { POST_API } from "../../../store/actions.type"

    export default {
        components: {
            BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm, BBreadcrumb,BFormRadio, BFormFile
        },
        directives: {
            'b-tooltip': VBTooltip,
        },

        data() {
            return {
                webUrl: process.env.VUE_APP_SERVER_URL,
                error_message: null, 
                showDismissibleAlert:false,

                /* tableColumns: [
                    { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '5%'}},
                    { key: 'image', label: 'Image', sortable: false , thStyle:  {width: '15%'}},
                    { key: 'name', label: 'Feedback', sortable: true , thStyle:  {width: '20%'}},
                    { key: 'category_type', label: 'Category Type', sortable: true , thStyle:  {width: '17%'}},
                    { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '15%'}},
                    { key: 'created_at', label: 'Added On', sortable: true , thStyle:  {width: '18%'}},
                    { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '10%'}},
                ], */
                
                sites:[],
                siteData:null,  

                // b-table and pagination options
                items           : [],
                items_paged     : [],
                isSortDirDesc   : true,
                perPageOptions  : [8, 24, 48, 96],
                perPage         : 24,
                sortBy          : 'id',
                totalRecords    : 0,
                currentPage     : 1,
                searchQuery     : '',
                from            : null,
                to              : null, 
                
                // multicheckbox vars
                selectAll : false,
                selected  : [],
                delType   : '',
                delStatus : '',
                password  : '',

                // Assign To Project Site vars
                all_sites : [],
                site_id  : null,
                all_locations  : [],
                locations:[],
                
                popshowDismissibleAlert:false,
                theme:'yellow',
                toilet_type:'custom',
                file: {
                    name:'',
                    image:null
                },
                total_selected : 0
            }
        },

        methods : {
            getCount(){
                this.total_selected = this.items.filter(obj => obj.selected === true).length;
            },
            // updateStatus(item){
            //     return this.$store.dispatch(POST_API, {
            //         data:{
            //             item:item
            //         },
            //         api: '/api/change-qr-category-status-card'
            //     })
            //     .then(() => {
            //         if (this.$store.getters.containsErrors) {
            //             this.error_message = this.$store.getters.getErrors;
            //         } else {
            //             var data = this.$store.getters.getResults;
            //             this.dataList();                        
            //         }
            //     });
            // },

            updateStatus(item){
                if (item.status == 'inactive' && item.locations.length > 0 ) {
                    var msg     = 'Some Locations linked with this category. Are you sure want to inactivate this record?';
                } else if (item.status == 'active') {
                    var msg     = 'Are you sure want to activate this record?';
                } else{
                    var msg     = 'Are you sure want to inactivate this record?';
                }

                this.decisionAlertBackdrop(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                item:item
                            },
                            api : "/api/change-qr-category-status-card",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                            } else {
                                var data = this.$store.getters.getResults.data;
                                // this.dataList();
                                this.items.forEach((el, ind) => {
                                    if(el._id == item._id){
                                        this.items[ind].selected = false;
                                        this.items[ind].locations = data.locations;
                                        this.items[ind].site_locations = data.site_locations;
                                    }
                                });
                                this.getCount();
                            }
                        });
                    } else {
                        // this.dataList();
                        this.items.forEach((el, ind) => {
                            if(el._id == item._id){
                                this.items[ind].status = item.status == 'active' ? 'inactive' : 'active';
                            }
                        });
                    }
                })
            },
            
            updateSelect(_id){
                this.items.forEach((el, index) => {
                    if(el.status == 'active' && el._id == _id){
                        this.items[index].selected = this.items[index].selected == true ? false : true;
                    }
                });
                this.getCount();
            },

            dataList(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        page          : this.currentPage,
                        keyword       : this.searchQuery,
                        rowsPerPage   : this.perPage,
                        sortBy        : this.sortBy,
                        sortDirection : this.isSortDirDesc,

                        role          : this.$store.getters.currentUser.role,
                        om_sites      : this.$store.getters.currentUser.om_sites,
                        site          : this.siteData ? this.siteData._id :null,
                    },
                    api: '/api/qr-categories-card'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items          = this.$store.getters.getResults.data.docs;
                        this.totalRecords   = this.$store.getters.getResults.data.total;

                        // this.perPage        = this.$store.getters.getResults.data.limit;
                        // this.from           = this.$store.getters.getResults.data.from
                        // this.to             = this.$store.getters.getResults.data.to

                        this.total_selected = 0;
                        this.updatePagination();
                    }
                });
            },

            filterTable(){
                this.dataList();
            },

            updatePagination(){
                this.items_paged = [];
                this.from = null;
                this.to = null;
                
                for (var i = (this.currentPage - 1) * this.perPage; i < (this.currentPage * this.perPage) && i < this.items.length; i++) {
                    this.items_paged.push(this.items[i]);
                }
                
                this.perPage = this.perPage;
                this.from    = this.items_paged.length > 0 ? (this.perPage * (this.currentPage -1)) + 1 : 0;
                this.to      = (this.perPage * (this.currentPage -1)) + this.items_paged.length;
            },

            variantColor(status){
                if (status == 'active') {
                    return 'success';
                }else{
                    return 'primary';
                }
            },

            statusChange(item){
                /* if (item.status == 'active' && item.locations.length > 0 ) {
                    
                    var msg     = 'Some Locations linked with this category. Are you sure want to inactivate this record?';

                } else if (item.status == 'active' && item.locations.length == 0 ) {
                    var msg     = 'Are you sure want to inactivate this record?';
                } else{
                    var msg     = 'Are you sure want to activate this record?';
                }

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) { */
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id     : item._id,
                                status : item.status
                            },
                            api : "/api/change-qr-category-status",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                // this.showAlert();
                            } else {
                                // this.successAlert();
                                this.dataList();
                            }
                        });
                   /*  }
                })
                .catch(err => {
                    console.log(err);
                }) */
            },

            deleteItem(id){
                var msg = 'Are you sure want to delete this record?';

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id     : id,
                                status : 'deleted'
                            },
                            api : "/api/change-qr-category-status",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;

                                this.showAlert();
                            } else {
                                this.successAlert();

                                this.dataList();
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },

            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                        om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        
                        this.all_sites = JSON.parse(JSON.stringify(this.$store.getters.getResults.data));

                        if (this.all_sites.length == 1 ) {
                            this.site_id = this.sites[0];
                            
                            this.getLocations();

                        }
                        
                        
                        return this.all_sites;
                    }
                });
            },

            // multicheckbox functions
            selectall(e){
                this.selected = [];
                if (e) {
                    var selected = [];

                    this.items.forEach(function(item){
                        selected.push(item._id);
                    })

                    this.selected = selected;
                }
            },

            changeMultipleStatus(status){
                this.password = '';

                this.delType = 'multiple';
                this.delStatus = status;

                this.selected  = [];

                this.selected = this.items.reduce(function(ids, obj){
                    if(obj.selected === true){
                        ids.push(obj._id);
                    }
                    return ids;
                }, []);

                if (this.selected.length > 0) {
                    var msg = '';
                    var msg2 = '';

                    if (status == 'archive') {
                        msg = 'Are you sure you want to delete selected record?';
                        msg2 = 'Records Deleted Successfully.';
                    }if (status == 'active') {
                        msg = 'Are you sure you want to Activate selected record?';
                        msg2 = 'Records Activated Successfully.';
                    }if (status == 'inactive') {
                        msg = 'Are you sure you want to inactive selected record?';
                        msg2 = 'Records Inactivated Successfully.';
                    }

                    this.decisionAlert(msg)
                    .then(result => {
                        if (result.value) {
                            /*this.$refs['pass'].show();*/
                            this.deleteMultiQuestion();
                        }
                    })
                    .catch(err => {
                        // An error occurred
                    })   
                }else{
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Please select QR Category(s).',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            },

            verifyPassword(e){
                e.preventDefault();

                return this.$store.dispatch(POST_API, {
                    data:{
                        user_id: this.$store.getters.currentUser._id,
                        password:this.password
                    },
                    api:"/api/verify-password",
                    })
                    .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                    } else {
                        this.deleteMultiQuestion();
                        this.$refs['pass'].hide();
                    }
                });
            },

            deleteMultiQuestion(){
                var message='Project Site Deleted Successfully.';
                
                return this.$store.dispatch(POST_API, {
                    data:{
                        id     : this.selected,
                        status :'deleted'
                        },
                    api:"/api/change-qr-category-status-multi",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.successAlert();
                        this.dataList();
                        this.selectAll = false;
                    }
                });
            },

            // Assign To Project Site functions
            openAssignSiteModel(){
                this.popshowDismissibleAlert = false;
                //this.site = null;
                this.site_id = null;
                this.locations = [];
                this.all_locations = [];
                this.toilet_type = 'custom';
                this.theme = 'yellow';

                this.file = {
                    name:'',
                    image:null
                };
                

                if (this.all_sites.length == 1 ) {
                    
                    this.site_id = this.all_sites[0];
                    
                    this.getLocations();

                }


                this.$refs['assign_site'].show();
            },

            generateQR(bvModalEvt){
                bvModalEvt.preventDefault()

                var location = null;
                if(this.toilet_type == 'custom'){
                    location = this.locations;
                } else {
                    if (this.toilet_type != '') {
                        var toi = this.toilet_type;
                        location = this.all_locations.filter(item => { return item.toilet_type == toi ;});
                    }
                }

                var api = this.theme == 'custom' ? '/api/genearte-custom-qr-for-feedback': '/api/genearte-qr-for-feedback';
                //console.log(location);
                return this.$store.dispatch(POST_API, {
                        data:{
                            site : this.site_id ? this.site_id : null,
                            locations : location,
                            theme:this.theme,
                            toilet_type:this.toilet_type,
                            file:this.file
                        },
                        api : api
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.popshowDismissibleAlert = true;
                        } else {
                            this.popshowDismissibleAlert = false;
                            var data  = this.$store.getters.getResults.data;
                            var message  = this.$store.getters.getResults.message;
                            this.successAlert()
                            .then((result) => {
                                
                                window.open(data,'_blank');

                                this.dataList();
                                
                                this.$refs['assign_site'].hide();
                                this.site_id  = null;
                                
                            });
                        }
                    });
            },
            showAllLocations(item){
                var arr = [];
                arr = item.map((t) => {
                    return t.name
                })

                return arr.join(', ');
            },
            getLocations(){
              
              this.locations = [];

              return this.$store.dispatch(POST_API, {
                   data:{
                     site: this.site_id ? this.site_id._id:null,
                     type:'toilet'
                   },
                   api: '/api/all-locations'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        this.all_locations = data;
                        return this.all_locations;
                    }
                });
            },
            openLocationModel(){
                this.popshowDismissibleAlert = false;
                this.site_id = null;
                this.locations = [];
                this.all_locations = [];
                this.toilet_type = 'custom';

                if (this.all_sites.length == 1 ) {
                    
                    this.site_id = this.all_sites[0];
                    
                    this.getLocations();

                }
                this.$refs['assign_location'].show();
            },

            updateLocations(bvModalEvt){
                bvModalEvt.preventDefault()

                this.selected  = [];

                this.selected = this.items.reduce(function(ids, obj){
                    if(obj.selected === true){
                        ids.push(obj._id);
                    }
                    return ids;
                }, []);

                return this.$store.dispatch(POST_API, {
                        data:{
                            site : this.site_id ? this.site_id : null,
                            locations : this.locations,
                            ids:this.selected,
                            toilet_type:this.toilet_type
                        },
                        api : '/api/add-site-qr-category'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.popshowDismissibleAlert = true;
                        } else {
                            this.popshowDismissibleAlert = false;
                            var data  = this.$store.getters.getResults.data;
                            var message  = this.$store.getters.getResults.message;
                            this.successAlert()
                            .then((result) => {
                                this.selected = [];
                                this.selectAll = false;
                                this.dataList();
                                this.$refs['assign_location'].hide();
                                this.site_id  = null;
                                
                            });
                        }
                    });
            },

            breadCrumb(){
              var item = [{
					to:{name:'client-dashboard'},
					text: 'Dashboard',
				},{
					to:null,
					text: 'Feedback System',
				},{
					to:null,
					text: 'Settings',
				},{
					to:null,
					text:'Category',
					active:true
				}];
				return item;
            },


            onFileChange(event) {
            
              this.file.image = null;
              this.file.name = '';
              var input = event.target;
              
              if (input.files && input.files[0]) {
                  var reader = new FileReader();
                  reader.readAsDataURL(input.files[0]);
                  var file = input.files[0];
                  
                  console.log(file);
                  if (file.type != 'image/png' ) {
                      
                      this.file.file = null;
                      this.file.name = '';
                      
                      
                      this.$refs['uploadFile'].reset()
                      //this.bulkFile = null;

                      this.showDismissibleAlert=false;
                      Swal.fire({
                        icon: 'error',
                        title: "Please upload .png Image",
                      })
                  } else if (file.size > 2097152) {

                      file="";
                      this.file.image = null;
                      this.file.name = '';
                      this.showDismissibleAlert=false;
                      this.$refs['uploadFile'].reset()
                      this.bulkFile = null;

                      Swal.fire({
                        icon: 'error',
                        title: "Maximum 20 MB image are allowed to upload"
                      })
                  } else {
                      reader.onload = (e) => {
                          
                          this.file.image = e.target.result;
                          this.file.name = file.name;

                          this.showDismissibleAlert=false;
                      }
                  }
              }
            },
            resetFile(){
                this.file = {
                    image : null,
                    name:''
                }

                return this.file;
            }
        },
        mounted(){
            this.allSites();
            this.dataList();
        }
    }
</script>

<style lang="scss" scoped>
    .border-danger {
        border: 2px solid #ea5455 !important;
        padding: 2px !important;
    }
    .border-secondary {
        border: 2px solid rgb(221, 221, 221) !important;
        padding: 2px !important;
    }
    .card-body{
        padding-top: 1rem !important;
    }
    .label-space{
        margin-left: 10px !important;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
